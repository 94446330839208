import React from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import {
  fragmentServiceText,
  fragmentServiceIconBox,
  fragmentServiceCheckList,
  fragmentServiceTestimonialBox,
  fragmentServiceTrainingCards,
  fragmentServiceImage,
  Sections,
  PrismicSection,
} from '../components/Sections';

import { FluidImage } from '../components/Trainings/types';

interface Props {
  data: {
    prismic: {
      service: {
        header_imageSharp: FluidImage;
        header_subtitle: string;
        header_title: string;
        seo_page_title: string;
        body: PrismicSection[];
      };
    };
  };
}

export default function Service({
  data: {
    prismic: { service },
  },
}: Props) {
  if (!service) {
    return null;
  }

  return (
    <Layout
      heroImage={service.header_imageSharp}
      subtitle={service.header_subtitle}
      title={service.header_title}
    >
      <Seo title={service.seo_page_title} />
      <Sections data={service.body} />
    </Layout>
  );
}

export const query = graphql`
  query getService($uid: String!, $lang: String!) {
    prismic {
      service(uid: $uid, lang: $lang) {
        header_caption
        header_title
        header_subtitle
        header_image
        header_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cta_label
        cta_target
        seo_page_title
        body {
          ...ServiceText
          ...ServiceTrainingCards
          ...ServiceIconBox
          ...ServiceTestimonialBox
          ...ServiceCheckList
          ...ServiceImage
        }
      }
    }
  }
`;

Service.fragments = [
  fragmentServiceText,
  fragmentServiceIconBox,
  fragmentServiceCheckList,
  fragmentServiceTestimonialBox,
  fragmentServiceTrainingCards,
  fragmentServiceImage,
];
